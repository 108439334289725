"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getShortAccount = exports.normalizeNetworkName = void 0;
const normalizeNetworkName = (network) => {
    if (!network) {
        return '';
    }
    if (network.length > 4) {
        return network.slice(0, 4).toUpperCase();
    }
    return network.toUpperCase();
};
exports.normalizeNetworkName = normalizeNetworkName;
const getShortAccount = (account) => {
    if (!account) {
        return '';
    }
    return account.substring(0, 5) + '…' + account.substring(account.length - 4);
};
exports.getShortAccount = getShortAccount;
